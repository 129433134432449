// src/App.js
import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LoginPage from './pages/login/LoginPage';
import Navbar from './components/navbar/Navbar';
import Menu from './components/menu/Menu';
import Auth from 'app/components/auth/Auth';
import ProtectedRoute from './components/protectedroute/ProtectedRoute';

import Customers from './pages/customers/Customers';
import UpdateCustomer from './pages/customer/UpdateCustomer';
import NewCustomer from './pages/customer/NewCustomer';

import Items from './pages/items/Items';
import NewItem from './pages/item/NewItem';
import UpdatedItem from './pages/item/UpdatedItem';

import Sales from './pages/sales/Sales';
import NewSale from './pages/sale/NewSale';

import { setMenuData } from 'app/store/menu/menuSlice';

import NoAccessPage from './components/noaccesspage/NoAccessPage';
import { Outlet } from 'react-router-dom';
import './styles/global.scss';

function App() {
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();


  useEffect(() => {
    function handleResize() {
      const isMobile = window.innerWidth <= 768;
      dispatch(setMenuData({ isMobileDevice: isMobile, openMenu: !isMobile }));
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);

  const Layout = () => {
    const menuOpen = useSelector((state) => state.menu.openMenu);

    return (
      <div className="main">
        <Navbar user={user} />
        <div className={`container ${menuOpen ? '' : 'container-close'}`} >
          <div className="menuContainer">
            <Menu user={user} />
          </div>
          <div className="contentContainer">
            <Outlet />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Auth>

      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<Layout />}>
          <Route path="/no-access" element={<NoAccessPage />} />

          <Route element={<ProtectedRoute user={user} tag="customers" requiredPermission="show" />}>
            <Route path="/customers" element={<Customers />} />
          </Route>

          <Route element={<ProtectedRoute user={user} tag="customers" requiredPermission="create" />}>
            <Route path="/customers/new" element={<NewCustomer />} />
          </Route>

          <Route element={<ProtectedRoute user={user} tag="customers" requiredPermission="update" />}>
            <Route path="/customers/:id" element={<UpdateCustomer />} />
          </Route>

          <Route element={<ProtectedRoute user={user} tag="items" requiredPermission="show" />}>
            <Route path="/items" element={<Items />} />
          </Route>

          <Route element={<ProtectedRoute user={user} tag="items" requiredPermission="create" />}>
            <Route path="/items/new" element={<NewItem />} />
          </Route>

          <Route element={<ProtectedRoute user={user} tag="items" requiredPermission="update" />}>
            <Route path="/items/:id" element={<UpdatedItem />} />
          </Route>

          <Route element={<ProtectedRoute user={user} tag="sales" requiredPermission="show" />}>
            <Route path="/sales" element={<Sales />} />
          </Route>

          <Route element={<ProtectedRoute user={user} tag="items" requiredPermission="create" />}>
            <Route path="/sales/new" element={<NewSale />} />
          </Route>

          {/* <Route element={<ProtectedRoute user={user} tag="items" requiredPermission="update" />}>
              <Route path="/items/:id" element={<UpdatedItem />} />
            </Route> */}




          {/* <Route element={<ProtectedRoute user={user} tag="items" />}>
              <Route path="/products" element={<Products />} />
            </Route>
            <Route element={<ProtectedRoute user={user} tag="sales" />}>
              <Route path="/sales" element={<div>sales Page</div>} />
            </Route> */}

          {/* <Route element={<ProtectedRoute user={getData(user)} tag="" />}>
              <Route path="/customers" element={<Customers />} />
              <Route path="/products" element={<div>Products Page</div>} />
              <Route path="/users/:id" element={<div>User Details Page</div>} />
              <Route path="/products/:id" element={<div>Product Details Page</div>} />
            </Route> */}
          <Route element={<ProtectedRoute allowedRoles={['admin']} />}>
            <Route path="/admin" element={<div>Admin Page</div>} />
          </Route>
        </Route>
      </Routes>

    </Auth>
  );
}
export default App;
