import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Form from "app/components/form/Form";
import CircularProgress from "app/components/circularprogress/CircularProgress";
import { setOpenMenu } from 'app/store/menu/menuSlice';

import CustomerService from 'app/services/customer/customerService';
import LogService from 'app/services/logs/logService';

import "./customer.scss";

const UpdateCustomer = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.menu.isMobileDevice);

  const { id } = useParams();
  const userData = useSelector(state => state.user.user.user);
  const [isLoading, setIsLoading] = useState(true);
  const [customer, setCustomer] = useState([]);
  const [logsCustomer, setLogsCustomer] = useState([]);
  
  const columns = [
    {
      field: "reg_number",
      type: "string",
      headerName: "ID",
      showFormEdit: true,
      disabled: true,

    },
    {
      field: "identification",
      type: "number",
      headerName: "Cedula",
      showFormEdit: true,
      disabled: false,
    },
    {
      field: "name",
      type: "string",
      headerName: "Nombre",
      showFormEdit: true,
      disabled: false,
    },
    {
      field: "birthdate",
      type: "datetime",
      headerName: "Fecha Nacimiento",
      showFormEdit: true,
      disabled: false,
    },
    {
      field: "phone",
      type: "number",
      headerName: "Telefono",
      showFormEdit: true,
      disabled: false,
    },
    {
      field: "mobile",
      type: "number",
      headerName: "Celular",
      showFormEdit: true,
      disabled: false,
    },
    {
      field: "balance",
      type: "number",
      headerName: "Saldo",
      showFormEdit: true,
      disabled: true,
    },
    {
      field: "last_payment_date",
      type: "string",
      headerName: "F. Ultimo Abono",
      width: 200,
      showFormEdit: true,
      disabled: true,

    },
    {
      field: "last_payment_amount",
      type: "number",
      headerName: "V. Ultimo Abono",
      showFormEdit: true,
      disabled: true,

    },
    {
      field: "limit_credit",
      type: "number",
      headerName: "Limite credito",
      showFormEdit: true,
      disabled: false,

    },
    {
      field: "address",
      type: "string",
      headerName: "Direccion",
      showFormEdit: true,
      disabled: false,
    },
    {
      field: "send_phone",
      type: "boolean",
      headerName: "Enviar al celular",
      showFormEdit: true,
      disabled: false,
    },

  ];


  useEffect(() => {
    if (isMobile) {
      dispatch(setOpenMenu(false));
    }
    const fetchCustomers = async () => {
      setIsLoading(true)
      try {
        const dataCustomer = await CustomerService.get(id);
        if (dataCustomer) {
          setCustomer(dataCustomer);
          const logsCustomer = await LogService.getAll({ "code": dataCustomer["reg_number"], "limit":6, "sort_field":"creation_date" })
          if (Array.isArray(logsCustomer)) {
            setLogsCustomer(logsCustomer)
          }
        }

        setIsLoading(false)
      } catch (error) {
        console.log(error);
      }
    };
    fetchCustomers();
  }, [isMobile, id, dispatch]);

  const removeFields = (obj, fields) => {
    const updatedData = { ...obj };
    fields.forEach(field => {
      delete updatedData[field];
    });
    return updatedData;
  };

  const submit = async (dataUpdate) => {
    const Data = removeFields(dataUpdate, ["last_payment_amount", "last_payment_date", "reg_number"]);
    const response = await CustomerService.put(id, Data)
    if (response.status === 200) {
            
      await LogService.post({
          "user": userData.username,
          "process":"update",
          "module":"customers",
          "code": dataUpdate.reg_number,
          "name":dataUpdate.name,
          "description":`Se modifico el cliente con matrícula ${dataUpdate.reg_number.toUpperCase()} con saldo $ ${dataUpdate.balance} `

      })
    }
    
    return response
  }

  return (
    <div className="" >
      <div className="info">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Form 
          slug="cliente" 
          columns={columns} 
          submit={submit} 
          data={customer} 
          page="customers" 
          action="edit" 
          showLastActivites={true}
          activities={logsCustomer}
          showButtomSubmmit={true}
          
          />

        )}
      </div >
    </div >

  )
}

export default UpdateCustomer