import * as React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import authService from 'app/services/auth/authService';
import { CircularProgress } from '@material-ui/core';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useRef, useState } from 'react';

authService.init()

const theme = createTheme();

function LoginForm() {
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);

  const buttonRef = useRef(null);

  const validationSchema = Yup.object({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      rememberMe: false, 
    },
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      try {
        const response = await authService.login({
          username: values.username,
          password: values.password,
          remember_me: values.rememberMe, // Enviar el valor de Remember Me
        });
        if (!response) {
          console.error('Authentication failed');
          setNotification({ message: 'Authentication failed', severity: 'error' });
        } else {
          // console.log('Authentication successful:', response);
          setNotification({ message: 'Authentication successful', severity: 'success' });
        }
      } catch (error) {
        console.error('Error during authentication:', error);
        setNotification({ message: 'Error during authentication', severity: 'error' });
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const handleNotificationClose = () => {
    setNotification(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.errors.username && formik.touched.username}
              helperText={formik.errors.username && formik.touched.username ? formik.errors.username : ''}
            />
            <TextField
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.errors.password && formik.touched.password}
              helperText={formik.errors.password && formik.touched.password ? formik.errors.password : ''}
            />
            <FormControlLabel
              control={<Checkbox value={formik.values.rememberMe} color="primary" onChange={formik.handleChange} name="rememberMe" />}
              label="Remember me"
            />
            <Button
              ref={buttonRef}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={formik.isSubmitting}
            >
              {loading ? <CircularProgress size={24} /> : 'Sign In'}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Snackbar
        open={notification !== null}
        autoHideDuration={6000}
        onClose={handleNotificationClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleNotificationClose}
          severity={notification?.severity || 'info'}
        >
          {notification?.message || ''}
        </MuiAlert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default LoginForm;
