import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import DataTable from "app/components/dataTable/DataTable";
import LoadingCircularProgress from "app/components/circularprogress/CircularProgress";



import Snackbar from '@mui/material/Snackbar';


import EditIcon from '@mui/icons-material/Edit';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';


import TransactionsService from 'app/services/sales/SalesService';

import { setOpenMenu } from 'app/store/menu/menuSlice';

import "./sales.scss";

const Sales = () => {

    const dispatch = useDispatch();
    const isMobile = useSelector((state) => state.menu.isMobileDevice);
    const [transaction, setTransaction] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const userData = useSelector(state => state.user.user.user);
    // const [reloading, setReloading] = useState(false);
  
    const [openSnackbar, setOpenSnackbar] = useState(false);
  

    const columns = [
        {
            field: "number",
            type: "string",
            headerName: "Factura",
            showTable: true,
            Cell: ({ value }) => {
                if (typeof value === 'string' && value.length > 0) {
                    return value.toUpperCase();
                } else {
                    return '';
                }
            }
        },
        {
            field: "sub_type",
            type: "string",
            headerName: "Transaccion",
            showTable: true,
        },
        {
            field: "reg_number",
            type: "string",
            headerName: "Matricula",
            showTable: true,
            Cell: ({ value }) => {
                if (typeof value === 'string' && value.length > 0) {
                    return value.toUpperCase();
                } else {
                    return '';
                }
            }
        },
        {
            field: "customer_name",
            type: "string",
            headerName: "Nombre",
            showTable: true,
        },
        {
            field: "subtotal",
            type: "number",
            headerName: "Subtotal",
            showTable: true,
            Cell: ({ value }) => {
                if (typeof value === 'number') {
                    return value.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 });
                } else {
                    return 0;
                }
            }
        }
        ,
        {
            field: "discount",
            type: "number",
            headerName: "descuento",
            showTable: true,
            Cell: ({ value }) => {
                if (typeof value === 'number') {
                    return value.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 });
                } else {
                    return 0;
                }
            }
        }
        ,
        {
            field: "total",
            type: "number",
            headerName: "Total",
            showTable: true,
            Cell: ({ value }) => {
                if (typeof value === 'number') {
                    return value.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 });
                } else {
                    return 0;
                }
            }
        },
        {
            field: "user",
            type: "string",
            headerName: "Usuario",
            showTable: true,
            Cell: ({ value }) => {
                if (typeof value === 'string' && value.length > 0) {
                  return value.charAt(0).toUpperCase() + value.slice(1);
                } else {
                  return '';
                }
              }
        },
    
        {
            field: "creation_date",
            type: "datetime",
            headerName: "Fecha",
            showTable: true,

        },
        {
            field: "bu",
            type: "string",
            headerName: "",
            showTable: true,
            Cell: ({ row }) => (
                <div className="buttons" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                    {
                        userData.sales.update ?
                            <Link to={`/transaction/${row.original.id}`}>
                                <EditIcon className="small-icon buttons-edit" />
                            </Link>
                            :
                            ""
                    }

                </div>
            ),

        }

    ];

    useEffect(() => {
        setIsLoading(true)
        if (isMobile) {
            dispatch(setOpenMenu(false));
        }
        const fetchtransaction = async () => {

            try {
                const dataTransaction = await TransactionsService.getAll({"type":"sales", "limit":2000, "sort_field":"creation_date"});
                if (Array.isArray(dataTransaction)) {
                    setTransaction(dataTransaction);
                }
                setIsLoading(false)
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchtransaction();
    }, [isMobile, dispatch]);


    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <div className="sales">
            <div className="info">
                <h1>Ventas</h1>
                {userData.sales.create ?
                    <Link to={"/sales/new"} className='button'>
                        <PointOfSaleIcon />
                    </Link>

                    :
                    ""
                }
            </div>
            {isLoading ? (
                <LoadingCircularProgress />
            ) : (
                <DataTable slug="sales" columns={columns} rows={transaction} edit={userData.sales.update} delete={userData.sales.delete} />
            )}



            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              
            </Snackbar>

        </div>
    );
};

export default Sales;
