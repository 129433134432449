import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Form from "app/components/form/Form";
import CircularProgress from "app/components/circularprogress/CircularProgress";
import { setOpenMenu } from 'app/store/menu/menuSlice';

import itemService from 'app/services/item/itemService';
import LogService from 'app/services/logs/logService';

import CategoryService from 'app/services/category/categoryService';
import SubCategoryService from 'app/services/subcategory/subcategoryService';
import ReferenceService from 'app/services/reference/referenceService';
import BrandService from 'app/services/brand/brandService';

import "./item.scss";

const UpdatedItem = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.menu.isMobileDevice);

  const { id } = useParams();
  const userData = useSelector(state => state.user.user.user);
  const [isLoading, setIsLoading] = useState(true);
  const [item, setitem] = useState([]);
  const [logsitem, setLogsitem] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, SetSubCategories] = useState([]);
  const [references, setReferences] = useState([]);
  const [brands, setBrands] = useState([]);

  const columns = [

    {
      field: "code",
      type: "string",
      headerName: "Codigo",
      showFormEdit: true,
      disabled: true,
    },
    {
      field: "category",
      type: "select",
      headerName: "Categoria",
      showFormEdit: true,
      disabled: false,
      options: categories,
      onChange: (selectedOption, formData, setFormData) => {
        let value = selectedOption ? selectedOption.value : '';
        let label = selectedOption ? selectedOption.label : '';
        let updatedCode = `${value || ''}${formData.sub_category.value || ''}${formData.reference.value || ''}${formData.brand.value || ''}`.toLowerCase();
        let updatedDescription = `${label || ""} ${formData.sub_category.label || ""} ${formData.reference.label || ""} de ${formData.brand.label || ""}`

        let subcategoryLabel = formData.sub_category && formData.sub_category.label ? formData.sub_category.label.substring(0, 3) : '';
        let referenceLabel = formData.reference && formData.reference.label ? formData.reference.label.substring(0, 3) : '';
        let labelSubstring = label ? label.substring(0, 3) : '';
        let brandLabel = formData.brand && formData.brand.label ? formData.brand.label.substring(0, 3) : '';

        let updatedName = `${labelSubstring}${subcategoryLabel}${referenceLabel}${brandLabel}`.toUpperCase();


        setFormData({ ...formData, category: { value, label }, code: "20" + updatedCode, name: updatedName, description: updatedDescription });
      }
    },

    {
      field: "sub_category",
      type: "select",
      headerName: "Sub Categoria",
      showFormEdit: true,
      disabled: false,
      options: subcategories,
      onChange: (selectedOption, formData, setFormData) => {
        let value = selectedOption ? selectedOption.value : '';
        let label = selectedOption ? selectedOption.label : '';
        let updatedCode = `${formData.category.value || ''}${value || ''}${formData.reference.value || ''}${formData.brand.value || ''}`.toLowerCase();
        let updatedDescription = `${formData.category.value || ''} ${label || ''} ${formData.reference.value || ''} de ${formData.brand.value || ''}`.toLowerCase();

        let categoryLabel = formData.category && formData.category.label ? formData.category.label.substring(0, 3) : '';
        let referenceLabel = formData.reference && formData.reference.label ? formData.reference.label.substring(0, 3) : '';
        let labelSubstring = label ? label.substring(0, 3) : '';
        let brandLabel = formData.brand && formData.brand.label ? formData.brand.label.substring(0, 3) : '';

        let updatedName = `${categoryLabel}${labelSubstring}${referenceLabel}${brandLabel}`.toUpperCase();


        setFormData({ ...formData, sub_category: { value, label }, code: "20" + updatedCode, name: updatedName, description: updatedDescription });
      }
    },
    {
      field: "reference",
      type: "select",
      headerName: "Referencia",
      showFormEdit: true,
      disabled: false,
      options: references,
      onChange: (selectedOption, formData, setFormData) => {
        let value = selectedOption ? selectedOption.value : '';
        let label = selectedOption ? selectedOption.label : '';
        let updatedCode = `${formData.category.value || ''}${formData.sub_category.value || ''}${value || ''}${formData.brand.value || ''}`.toLowerCase();
        let updatedDescription = `${formData.category.label || ''}${formData.sub_category.label || ''}${label || ''} de ${formData.brand.label || ''}`.toLowerCase();

        let categoryLabel = formData.category && formData.category.label ? formData.category.label.substring(0, 3) : '';
        let subcategoryLabel = formData.sub_category && formData.sub_category.label ? formData.sub_category.label.substring(0, 3) : '';
        let labelSubstring = label ? label.substring(0, 3) : '';
        let brandLabel = formData.brand && formData.brand.label ? formData.brand.label.substring(0, 3) : '';

        let updatedName = `${categoryLabel}${subcategoryLabel}${labelSubstring}${brandLabel}`.toUpperCase();



        setFormData({ ...formData, reference: { value, label }, code: "20" + updatedCode, name: updatedName, description: updatedDescription });
      }
    },
    {
      field: "brand",
      type: "select",
      headerName: "Marca",
      showFormEdit: true,
      disabled: false,
      options: brands,
      onChange: (selectedOption, formData, setFormData) => {
        let value = selectedOption ? selectedOption.value : '';
        let label = selectedOption ? selectedOption.label : '';
        let updatedCode = `${formData.category.value || ''}${formData.sub_category.value || ''}${formData.reference.value || ''}${value || ''}`.toLowerCase();
        let updatedDescription = `${formData.category.label || ''} ${formData.sub_category.label || ''} ${formData.reference.label || ''} de ${label || ''}`.toLowerCase();

        let categoryLabel = formData.category && formData.category.label ? formData.category.label.substring(0, 3) : '';
        let subcategoryLabel = formData.sub_category && formData.sub_category.label ? formData.sub_category.label.substring(0, 3) : '';
        let referenceLabel = formData.reference && formData.reference.label ? formData.reference.label.substring(0, 3) : '';
        let labelSubstring = label ? label.substring(0, 3) : '';

        let updatedName = `${categoryLabel}${subcategoryLabel}${referenceLabel}${labelSubstring}`.toUpperCase();

        setFormData({ ...formData, brand: { value, label }, code: "20" + updatedCode, name: updatedName, description: updatedDescription });
      }
    },
    {
      field: "name",
      type: "string",
      headerName: "Nombre",
      showFormEdit: true,
      disabled: false,
    },

    {
      field: "minimum_stock",
      type: "number",
      headerName: "Stock Minimo",
      showFormEdit: true,
      disabled: false,
    },
    {
      field: "cost_price",
      type: "number",
      headerName: "Costo",
      showFormEdit: true,
      disabled: false,
    },
    {
      field: "selling_price",
      type: "number",
      headerName: "Precio",
      showFormEdit: true,
      disabled: false,
    },
    {
      field: "stock",
      type: "number",
      headerName: "Stock",
      showFormEdit: true,
      disabled: true,

    },
    {
      field: "description",
      type: "task",
      headerName: "Descripcion",
      showFormEdit: true,
      disabled: false,

    },
    

  ];



  useEffect(() => {
    const fetchItems = async () => {
      setIsLoading(true);

      if (isMobile) {
        dispatch(setOpenMenu(false));
      }

      try {
        // Fetch data from services
        const [categoriesData, subcategoriesData, referencesData, brandsData] = await Promise.all([
          CategoryService.getAll(),
          SubCategoryService.getAll(),
          ReferenceService.getAll(),
          BrandService.getAll()
        ]);

        // Update state based on fetched data
        if (Array.isArray(categoriesData)) {
          setCategories(categoriesData);
        }
        if (Array.isArray(subcategoriesData)) {
          SetSubCategories(subcategoriesData);
        }
        if (Array.isArray(referencesData)) {
          setReferences(referencesData);
        }
        if (Array.isArray(brandsData)) {
          setBrands(brandsData);
        }

        // Fetch and set item data
        const dataitem = await itemService.get(id);
        if (dataitem) {
          setitem(dataitem);

          // Fetch and set logs based on item code
          const logsitem = await LogService.getAll({ "code": dataitem["code"], "limit":6, "sort_field":"creation_date" });
          if (Array.isArray(logsitem)) {
            setLogsitem(logsitem);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchItems();
  }, [isMobile, id, dispatch, setCategories, SetSubCategories, setReferences, setBrands, setitem, setLogsitem]);

  const submit = async (data) => {
    data.category = data.category.label
    data.sub_category = data.sub_category.label
    data.reference = data.reference.label
    data.brand = data.brand.label
    const response = await itemService.put(id, data)
    if (response.status === 200) {
      await LogService.post({
        "user": userData.username,
        "process": "update",
        "module": "items",
        "code": data.code,
        "name": data.name,
        "description": `Se actulizo  
        el producto ${data.name.toUpperCase()} 
        (${data.code.toUpperCase()}) 
        con un inventario de ${data.stock}, un costo de $ ${data.cost_price} y 
        un precio de venta de $ ${data.selling_price}`
      })
    }

    return response
  }

  return (
    <div className="" >
      <div className="info">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Form
            slug="producto"
            columns={columns}
            submit={submit}
            data={item}
            page="items"
            action="edit"
            showLastActivites={true}
            activities={logsitem}
            showButtomSubmmit={true}

          />

        )}
      </div >
    </div >

  )
}

export default UpdatedItem