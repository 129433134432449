import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import DataTable from "app/components/dataTable/DataTable";
import LoadingCircularProgress from "app/components/circularprogress/CircularProgress";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import QrCode2Icon from '@mui/icons-material/QrCode2';

import { CircularProgress } from '@material-ui/core';
import { generateBarcodeAndDownload } from 'app/components/barcode/Barcode'

import ItemService from 'app/services/item/itemService';
import LogService from 'app/services/logs/logService';
import { setOpenMenu } from 'app/store/menu/menuSlice';

import "./items.scss";

const Items = () => {

  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.menu.isMobileDevice);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selecteditemId, setSelecteditemId] = useState(null);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userData = useSelector(state => state.user.user.user);
  const [reloading, setReloading] = useState(false);
  const [loadingButton, setLoadingButtons] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const columns = [
    {
      field: "code",
      type: "string",
      headerName: "Codigo",
      showTable: true,
    },
    {
      field: "name",
      type: "string",
      headerName: "Nombre",
      showTable: true,
    },
    {
      field: "description",
      type: "string",
      headerName: "Descripcion",
      showTable: true,
    },
    {
      field: "stock",
      type: "numbre",
      headerName: "Stock",
      showTable: true,
    },
    {
      field: "minimum_stock",
      type: "numbre",
      headerName: "Stock minimo",
      showTable: true,
    },
    {
      field: "selling_price",
      type: "number",
      headerName: "Precio",
      showTable: true,
      Cell: ({ value }) => {
        if (typeof value === 'number') {
          return value.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 });
        } else {
          return 0;
        }
      },
    },

    {
      field: "bu",
      type: "string",
      headerName: "",
      showTable: true,
      Cell: ({ row }) => (
        <div className="buttons" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
          {
            userData.items.update ?
              <Link to={`/items/${row.original.id}`}>
                <EditIcon className="small-icon buttons-edit" />
              </Link>
              :
              ""
          }

          {
            loadingButton[row.original.id] ?
              <CircularProgress />
              :
              userData.items.delete ?
                <Button
                  className="buttons-deleted"
                  color="secondary"
                  onClick={() => {
                    setSelecteditemId(row.original);
                    setDialogOpen(true);
                  }}
                >
                  <DeleteIcon className="small-icon" />
                </Button>
                :
                ""
          }
          <Button
            className="buttons-edit"
            color="secondary"
            onClick={() => {
              generateBarcodeAndDownload(row.original.code);
            }}
          >
            <QrCode2Icon className="small-icon" />
          </Button>
        </div>
      ),
    }
  ];

  useEffect(() => {
    setIsLoading(true);
    if (isMobile) {
      dispatch(setOpenMenu(false));
    }
    const fetchItems = async () => {
      try {
        const dataitems = await ItemService.getAll();
        if (dataitems) {
          setItems(dataitems);
        }
      } catch (error) {
        console.error('Error fetching items:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchItems();
  }, [reloading, isMobile, dispatch]);



  const deleteitem = async (row) => {
    setLoadingButtons(prevState => ({
      ...prevState,
      [row.id]: true
    }));

    try {
      const response = await ItemService.delete(row.id);

      if (response.status !== 200) {
        setSnackbarMessage(response);
        setOpenSnackbar(true);
        setLoadingButtons(prevState => ({
          ...prevState,
          [row.id]: false
        }));
        return;
      }

      else {
        await LogService.post({
          "user": userData.username,
          "process": "delete",
          "module": "items",
          "code": row.code,
          "name": row.name,
          "description": `Se elimino  
        el producto ${row.name.toUpperCase()} 
        (${row.code.toUpperCase()}) 
        con un inventario de ${row.stock}, un costo de $ ${row.cost_price} y 
        un precio de venta de $ ${row.selling_price}`
        })
      }

      setLoadingButtons(prevState => ({
        ...prevState,
        [row.id]: false  
      }));
      setReloading(!reloading)
    } catch (error) {
      console.error("Error eliminando el producto:", error);
      setSnackbarMessage("Ocurrió un error al eliminar el producto.");
      setOpenSnackbar(true);
      setLoadingButtons(prevState => ({
        ...prevState,
        [row.id]: false  // Restablecer el indicador de carga
      }));
    }
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="items">
      <div className="info">
        <h1>Productos</h1>
        {userData.items.create ?
          <Link to={"/items/new"} className='button'>
            <AddIcon />
          </Link>
          :
          ""
        }
      </div>
      {isLoading ? (
        <LoadingCircularProgress />
      ) : (
        <DataTable slug="items" columns={columns} rows={items} edit={userData.items.update} delete={userData.items.delete} />
      )}


      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"¿Estás seguro de que deseas eliminar este producto?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esta acción no se puede deshacer. ¿Deseas continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => {
            deleteitem(selecteditemId);
            setDialogOpen(false);
          }} color="secondary" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%', marginTop: '100px' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </div>
  );
};

export default Items;
