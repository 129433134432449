import axios from 'axios';
import { BACKEND_URL, SECRET_KEY } from "config";
import CryptoJS from 'crypto-js';

const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
  'Access-Control-Allow-Origin': 'https://main.d2ts3tcts2n5xa.amplifyapp.com', // Dominio del frontend
};



class AuthService {
  
  constructor() {
    this.subscribers = {
      isAuthTokenValid: [],
      onAutoLogin: [],
      onAutoLogout: [],
      onNoAccessToken: [],
    };
    this.registeredEvents = new Set();
  }

  on(event, callback) {
    if (this.subscribers[event]) {
      this.subscribers[event].push(callback);
    }
  }

  off(event, callback) {
    if (this.subscribers[event]) {
      this.subscribers[event] = this.subscribers[event].filter(cb => cb !== callback);
    }
  }

  emit(event, data) {
    if (this.subscribers[event]) {
      this.subscribers[event].forEach(callback => callback(data));
    }
  }

  clearSubscriptions() {
    Object.values(this.subscribers).forEach(subscriber => {
      subscriber.length = 0; // Limpiar el array de suscriptores
    });
  }

  init() {
    if (this.registeredEvents.has('init')) return;
    this.registeredEvents.add('init');
    this.handleAuthentication();
  }

  encryptStr(plainStr) {
    const key = CryptoJS.enc.Utf8.parse(SECRET_KEY);
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(plainStr, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    const encryptedStr = CryptoJS.enc.Base64.stringify(iv.concat(encrypted.ciphertext));
    return encryptedStr;
  }

  decryptStr(encryptedText) {
    const encryptedData = CryptoJS.enc.Base64.parse(encryptedText);
    const iv = CryptoJS.lib.WordArray.create(encryptedData.words.slice(0, 4));
    const ciphertext = CryptoJS.lib.WordArray.create(encryptedData.words.slice(4));

    const key = CryptoJS.enc.Utf8.parse(SECRET_KEY);
    const decrypted = CryptoJS.AES.decrypt({ ciphertext }, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  decrypt(encryptedText) {
    try {
      // Decodifica la base64
      const encryptedData = CryptoJS.enc.Base64.parse(encryptedText);

      // Extrae el IV (primeros 16 bytes) y el ciphertext (el resto)
      const iv = CryptoJS.lib.WordArray.create(encryptedData.words.slice(0, 4));
      const ciphertext = CryptoJS.lib.WordArray.create(encryptedData.words.slice(4));

      // Convierte la clave en un formato adecuado
      const key = CryptoJS.enc.Utf8.parse(SECRET_KEY);

      // Configura el modo de operación y padding
      const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      // Convierte el resultado a texto
      const decryptedText = CryptoJS.enc.Utf8.stringify(decrypted);

      if (!decryptedText) {
        throw new Error('Failed to decrypt data.');
      }

      return decryptedText;
    } catch (error) {
      this.emit("onAutoLogout");
      console.error('Error during decryption:', error);
      throw new Error('Failed to decrypt data.');
    }
  }

  async login(credentials) {
    try {
      const { username, password, remember_me } = credentials;
      const encryptedPassword = this.encryptStr(password);
      const authHeader = 'Basic ' + btoa(username + ':' + encryptedPassword);

      const response = await axios.post(
        `${BACKEND_URL}/login`,
        { remember_me },
        {
          headers: {
            ...DEFAULT_HEADERS,
            'Authorization': authHeader
          },
          withCredentials: true
        }
      );
      const data = response.data;
      localStorage.setItem("access_token", data['access_token']);
      localStorage.setItem("data_user", data['data_user']);
      this.emit('onAutoLogin')

      return data;
    } catch (error) {
      console.error('Error en la solicitud:', error.response.data.detail);
      return null;
    }
  }

  signInWithToken() {

    return new Promise((resolve, reject) => {
   
      try {
        const data = this.getDataLocalStorage()
        const dataUser = this.decrypt(data.dataUser)

        resolve({
          isAuthenticated: true,
          user: this.getData(dataUser)
        })
     
      } catch (error) {
        this.emit("onNoAccessToken");
        console.error('Error en la solicitud:', error.response.data.detail);
        return null;
      }

    });
  }

  handleAuthentication = async () => {
    const data = this.getDataLocalStorage();
    const accessToken = data.accessToken;
    try {
      const response = await this.isAuthTokenValid(accessToken);
      if (response) {
        this.emit("onAutoLogin");
      } else {
        this.emit("onAutoLogout");
      }
    } catch (error) {
      this.emit("onAutoLogout");
      console.error('Error en la solicitud:', error.response?.data?.detail || error.message);
    }
  };
  getDataLocalStorage = () => {
    const accessToken = localStorage.getItem("access_token");
    const dataUser = localStorage.getItem("data_user");
    return { accessToken, dataUser };

  };

  isAuthTokenValid = async (access_token) => {
    try {
      const response = await axios.get(
        `${BACKEND_URL}/verify_token?access_token=${access_token}`
      );
      return response
    } catch (error) {
      this.emit("onAutoLogout");
      console.error('Error en la solicitud:', error.response.data.detail);
      return null;
    }
  };

  logout = async () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('data_user');
    localStorage.removeItem('datasales');
    document.cookie = "dataUser=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "loginToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // localStorage.clear();
  };

  getData = (dataUser) => {
    try {
      const jsonStringWithDoubleQuotes = dataUser.replace(/'/g, '"');
      let jsonStringCleaned = jsonStringWithDoubleQuotes.replace(/True/g, "true");
      jsonStringCleaned = jsonStringCleaned.replace(/False/g, "false");
      const cleanedJsonString = jsonStringCleaned.trim();
      return JSON.parse(cleanedJsonString);

    } catch (error) {

      this.emit("onAutoLogout");
      console.error('Error en la solicitud:', error.response.data.detail);
      return {};
    }
  }

}


const instance = new AuthService();

export default instance;
