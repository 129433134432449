import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Form from "app/components/form/Form";
import CircularProgress from "app/components/circularprogress/CircularProgress";
import { setOpenMenu } from 'app/store/menu/menuSlice';

import CustomerService from 'app/services/customer/customerService';
import LogService from 'app/services/logs/logService';

import "./customer.scss";



const NewCustomer = () => {
    const dispatch = useDispatch();
    const isMobile = useSelector((state) => state.menu.isMobileDevice);
    const userData = useSelector(state => state.user.user.user);
    const [isLoading, setIsLoading] = useState(true);
  
    const formData = { "limit_credit": 1000000 };
    
    const columns = [

        {
            field: "identification",
            type: "number",
            headerName: "Cedula",
            showFormNew: true,
            disabled: false,
        },
        {
            field: "name",
            type: "string",
            headerName: "Nombre",
            showFormNew: true,
            disabled: false,
        },
        {
            field: "birthdate",
            type: "datetime",
            headerName: "Fecha Nacimiento",
            showFormNew: true,
            disabled: false,
        },
        {
            field: "phone",
            type: "number",
            headerName: "Telefono",
            showFormNew: true,
            disabled: false,
        },
        {
            field: "mobile",
            type: "number",
            headerName: "Celular",
            showFormNew: true,
            disabled: false,
        },

        {
            field: "limit_credit",
            type: "number",
            headerName: "Limite credito",
            showFormNew: true,
            disabled: false,

        },
        {
            field: "address",
            type: "string",
            headerName: "Direccion",
            showFormNew: true,
            disabled: false,
        },
        {
            field: "send_phone",
            type: "boolean",
            headerName: "Enviar al celular",
            showFormNew: true,
            disabled: false,
        },

    ];

    useEffect(() => {
        if (isMobile) {
            dispatch(setOpenMenu(false));
        }
        setIsLoading(false)
    }, [isMobile, dispatch]);

    function formatDate(date) {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const submit = async (data) => {

        const letraAFiltrar = data.name.charAt(0).toLowerCase();
        const customers = await CustomerService.getAll({ "reg_number_contains": letraAFiltrar })
        let nuevaCadena = ""

        if (Array.isArray(customers)) {
            const registrosFiltrados = customers.filter(customers => customers.reg_number.toLowerCase().includes(letraAFiltrar.toLowerCase()));

            registrosFiltrados.sort((a, b) => {
                const regNumberA = parseInt(a.reg_number.replace(/\D/g, ''), 10);
                const regNumberB = parseInt(b.reg_number.replace(/\D/g, ''), 10);
                return regNumberB - regNumberA;
            });

            const cadena = registrosFiltrados[0].reg_number;

            const numero = parseInt(cadena.substring(1));
            const numeroSumado = numero + 1;
            nuevaCadena = cadena.charAt(0) + numeroSumado.toString().padStart(cadena.length - 1, '0');
        }

        else {
            nuevaCadena = letraAFiltrar + "001"
        }


        data.reg_number = nuevaCadena
        data.birthdate = formatDate(data.birthdate)

        try {
            const response = await CustomerService.post(data)
            if (response.status === 200) {
            
                await LogService.post({
                    "user": userData.username,
                    "process":"create",
                    "module":"customers",
                    "code": nuevaCadena,
                    "name":data.name,
                    "description":`Se creo el cliente con matrícula ${nuevaCadena.toUpperCase()}`

                })
                return response
            }
            return response
        } catch (error) {
            return error
        }
    }

    return (
        <div className="" >
            <div className="info">
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <Form
                        slug="cliente"
                        columns={columns}
                        submit={submit}
                        page="customers"
                        action="add"
                        showLastActivites={false}
                        showButtomSubmmit={true}
                        data={formData}
                    />
                )}
            </div >
        </div >
    )
}

export default NewCustomer