import { jsPDF } from 'jspdf';

// Función para cargar una imagen desde una URL y convertirla a base64
const loadImageAsBase64 = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.src = url;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL();
      resolve(dataURL);
    };
    img.onerror = (error) => {
      reject(error);
    };
  });
};

// Función para generar el PDF de la factura con un diseño profesional
export const generateInvoicePdf = async (data) => {
  const doc = new jsPDF();

  try {
    // Cargar el logo de la empresa desde la URL y convertirlo a Base64
    const logoBase64 = await loadImageAsBase64('https://logosmirnev.s3.sa-east-1.amazonaws.com/variedadesluz.png');

    // Estilos generales
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 15;

    // CABECERA
    doc.addImage(logoBase64, 'PNG', margin, 10, 50, 20); // Logo
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(20);
    doc.text('Variedades Luz', 70, 20);
    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');
    doc.text('Dirección: Calle 123 #45-67', 70, 30);
    doc.text('Teléfono: +57 123 456 7890', 70, 35);
    doc.text('Email: contacto@variedadesluz.com', 70, 40);

    // LÍNEA SEPARADORA
    doc.setLineWidth(0.5);
    doc.line(margin, 45, pageWidth - margin, 45);

    // DATOS DE FACTURA
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(12);
    doc.text('Factura de Venta', margin, 55);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);
    doc.text(`Número de Orden: ${data.number}`, margin, 65);
    doc.text(`Fecha: ${new Date().toLocaleDateString()}`, margin, 70);
    doc.text(`Atendido por: ${data.user}`, margin, 75);

    // DATOS DEL CLIENTE
    doc.setFont('helvetica', 'bold');
    doc.text('Información del Cliente', margin, 85);
    doc.setFont('helvetica', 'normal');
    doc.text(`Cliente: ${data.customer_name}`, margin, 90);
    doc.text(`Identificación: ${data.reg_number}`, margin, 95);

    // LÍNEA SEPARADORA
    doc.line(margin, 100, pageWidth - margin, 100);

    // TABLA DE ÍTEMS
    let yPosition = 110;
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(10);
    doc.text('Descripción', margin, yPosition);
    doc.text('Cantidad', pageWidth / 2 - 20, yPosition);
    doc.text('Precio Unitario', pageWidth / 2 + 30, yPosition);
    doc.text('Total', pageWidth - margin - 40, yPosition);

    doc.setFont('helvetica', 'normal');
    yPosition += 8;
    let subtotal = 0;

    data.items.forEach(item => {
      const totalPorItem = item.quantity * item.price;
      subtotal += totalPorItem;

      // Datos del producto
      doc.text(item.name, margin, yPosition);
      doc.text(item.quantity.toString(), pageWidth / 2 - 20, yPosition, { align: 'right' });
      doc.text(item.price.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }), pageWidth / 2 + 40, yPosition, { align: 'right' });
      doc.text(totalPorItem.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }), pageWidth - margin - 10, yPosition, { align: 'right' });

      yPosition += 8;
    });

    // LÍNEA SEPARADORA
    doc.line(margin, yPosition, pageWidth - margin, yPosition);
    yPosition += 10;

    // SUBTOTAL Y TOTAL
    const discount = data.discount || 0;
    const total = data.total || 0;
    const received = data.received || 0;
    const changeGiven = data.change_given || 0;

    doc.setFont('helvetica', 'normal');
    doc.text(`Subtotal:`, pageWidth - margin - 70, yPosition);
    doc.text(subtotal.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }), pageWidth - margin, yPosition, { align: 'right' });

    yPosition += 8;
    doc.text(`Descuento:`, pageWidth - margin - 70, yPosition);
    doc.text(discount.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }), pageWidth - margin, yPosition, { align: 'right' });

    yPosition += 8;
    doc.setFont('helvetica', 'bold');
    doc.text(`Total:`, pageWidth - margin - 70, yPosition);
    doc.text(total.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }), pageWidth - margin, yPosition, { align: 'right' });

    yPosition += 8;
    doc.setFont('helvetica', 'normal');
    doc.text(`Recibido:`, pageWidth - margin - 70, yPosition);
    doc.text(received.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }), pageWidth - margin, yPosition, { align: 'right' });

    yPosition += 8;
    doc.text(`Cambio Devuelto:`, pageWidth - margin - 70, yPosition);
    doc.text(changeGiven.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }), pageWidth - margin, yPosition, { align: 'right' });

    // MENSAJE DE AGRADECIMIENTO Y PIE DE PÁGINA
    yPosition += 20;
    doc.setFont('helvetica', 'italic');
    doc.setFontSize(10);
    doc.text('Gracias por su compra. Para cualquier consulta, no dude en contactarnos.', margin, yPosition);

    // Guardar el PDF automáticamente
    doc.save('factura_venta_profesional.pdf');
  } catch (error) {
    console.error('Error al cargar el logo:', error);
  }
};
