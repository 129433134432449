import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";

import DataTable from "app/components/dataTable/DataTable";
import Form from "app/components/form/Form";
import CircularProgress from "app/components/circularprogress/CircularProgress";
import { setOpenMenu } from 'app/store/menu/menuSlice';
import { Button, TextField } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import CustomerService from 'app/services/customer/customerService';
import itemService from 'app/services/item/itemService';
import TransactioncountsService from 'app/services/transactioncounts/transactioncountsService'
import TransactionsService from 'app/services/sales/SalesService';
import LogService from 'app/services/logs/logService';
import MessageService from "app/services/messsage/MessageService"
import { generateInvoicePdf } from "app/utils/pdf/PDF"
import QrCode2Icon from '@mui/icons-material/QrCode2';
import BarcodeScanner from 'app/components/barcodescanner/barcodeScanner'; // Importamos el componente de escaneo
import "./sale.scss";

const NewSale = () => {
    const dispatch = useDispatch();
    const isMobile = useSelector((state) => state.menu.isMobileDevice);
    const userData = useSelector(state => state.user.user.user);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState("contado");
    const [loadingButton, setLoadingButton] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [datacountnumber, setDatacountnumber] = useState({});
    const [isScannerVisible, setIsScannerVisible] = useState(false);
    const [scannedBarcode, setScannedBarcode] = useState('');
    const [isFetchingItems, setIsFetchingItems] = useState(false);
    const inputRefCode = useRef(null);
    const location = useLocation();

    const [ordernumber, setOrderNumber] = useState('');
    const [datasales, setDataSales] = useState({
        "reg_number": "",
        "identification": "",
        "name": "",
        "send_phone": false,
        "mobile": "",
        "subtotal": 0,
        "total": 0,
        "discount": 0,
        "balance_before": 0,
        "balance_after": 0,
        "received": 0,
        "change_given": 0,
        "items": []
    });
    const [columnsForm, setColumnsForm] = useState([
        {
            field: "reg_number",
            type: "string",
            headerName: "Matricula",
            showFormNew: false,
            disabled: false,
            onKeyDown: (e) => handleEnterKey(e, "customer"),
        },
        {
            field: "identification",
            type: "string",
            headerName: "Identificacion",
            showFormNew: false,
            disabled: false,
            onKeyDown: (e) => handleEnterKey(e, "customer"),
            renderExtra: (value, loadingButton) => {
                return (
                    <Button onClick={() => fetchCustomers(value)} className="button button-search" disabled={loadingButton}>
                        {loadingButton ? (
                            <CircularProgress className="CircularProgress" />
                        ) : (
                            <SearchIcon />
                        )}
                    </Button>
                );
            }
        },
        {
            field: "name",
            type: "string",
            headerName: "Nombre Cliente",
            showFormNew: true,
            disabled: false,
        },
        {
            field: "mobile",
            type: "string",
            headerName: "Celular",
            showFormNew: false,
            disabled: false,
        },
        {
            field: "send_phone",
            type: "boolean",
            headerName: "Enviar al celular",
            showFormNew: false,
            disabled: false,
            onChange: (e) => { handleChane(e, "send_phone") }
        },



    ]);

    const columnsFormItems = [
        {
            field: "code",
            type: "string",
            headerName: "Codigo de producto",
            showFormNew: true,
            disabled: false,
            onKeyDown: (e) => handleEnterKey(e, "item"),
            renderExtra: (value, loadingButton) => {
                return (
                    <>
                        <Button onClick={() => fetchItems(value)} className="button button-search" disabled={loadingButton}>
                            {loadingButton ? (
                                <CircularProgress className="CircularProgress" />
                            ) : (
                                <SearchIcon />
                            )}
                        </Button>

                        {isMobile ? (

                            < Button
                                onClick={() => setIsScannerVisible(!isScannerVisible)}
                                className="button button-search"
                                disabled={loadingButton}
                            >
                                {loadingButton ? (
                                    <CircularProgress className="CircularProgress" />
                                ) : (
                                    <QrCode2Icon className="small-icon" />
                                )}
                            </Button >

                        ) : null}
                    </>
                );
            }
        }
    ];

    const columnsTable = [

        {
            field: "code",
            type: "string",
            headerName: "Codigo",
            showTable: true,
            disabled: false,

        },
        {
            field: "name",
            type: "string",
            headerName: "Nombre",
            showTable: true,
            disabled: false,

        },
        {
            field: "price",
            type: "number",
            headerName: "Precio",
            showTable: true,
            showFormEdit: false,
            disabled: false,
            Cell: ({ value }) => {
                if (typeof value === 'number') {
                    return value.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 });
                } else {
                    return 0;
                }
            }
        },

        {
            field: "quantity",
            type: "number",
            headerName: "Cantidad",
            showTable: true,
            showFormEdit: false,
            disabled: false,
            Cell: ({ value, row, column }) => {
                const [localValue, setLocalValue] = useState(value);
                const inputRef = useRef(null);

                const handleLocalChange = (e) => {
                    const newValue = e.target.value;
                    setLocalValue(newValue);
                };

                const handleBlur = () => {
                    // Solo actualiza el estado global cuando el input pierde el foco
                    requestAnimationFrame(() => {
                        handleEditCell(row.index, column.id, localValue);
                    });
                };

                const handleKeyDown = (e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault(); // Prevenir comportamiento predeterminado de "Enter" (como enviar formularios)
                        // Envía los cambios cuando se presiona Enter
                        handleEditCell(row.index, column.id, localValue);
                        inputRef.current.blur(); // Desenfoca el input para disparar handleBlur si es necesario
                    }
                };

                return (
                    <TextField
                        inputRef={inputRef}
                        value={localValue}
                        onChange={handleLocalChange}
                        onBlur={handleBlur}  // Actualiza el estado global solo al perder el foco
                        onKeyDown={handleKeyDown}  // Maneja la tecla "Enter"
                        variant="outlined"
                        size="small"
                    />
                );
            },
        },
        {
            field: "subtotal",
            type: "number",
            headerName: "Subtotal",
            showTable: true,
            showFormEdit: false,
            disabled: false,
            Cell: ({ value }) => {
                if (typeof value === 'number') {
                    return value.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 });
                } else {
                    return 0;
                }
            }
        },

        {
            field: "bu",
            type: "string",
            headerName: "",
            width: 70,
            showForm: false,
            showTable: true,
            Cell: ({ row }) => (
                <div className="buttons" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                    <Button
                        className="buttons-deleted"
                        // color="secondary"
                        onClick={() => { handleDeleteRow(row) }}
                    >
                        <DeleteIcon className="small-icon" />
                    </Button>
                </div>
            ),

        }

    ];

    const [columnsFormBalance, setColumnsFormBalence] = useState([
        {
            field: "balance_before",
            type: "currency",
            headerName: "Saldo",
            showFormNew: false,
            disabled: true,
        },
        {
            field: "subtotal",
            type: "currency",
            headerName: "Subtotal",
            showFormNew: true,
            disabled: true,

        },
        {
            field: "discount",
            type: "number",
            headerName: "Descuento",
            showFormNew: true,
            disabled: false,
            onChange: (value, formData, setFormData) => {
                setFormData({ ...formData, total: formData.subtotal - value, discount: value });

            }

        },
        {
            field: "total",
            type: "currency",
            headerName: "Total",
            showFormNew: true,
            disabled: true,

        },
        {
            field: "balance_after",
            type: "currency",
            headerName: "Nuevo Saldo",
            showFormNew: false,
            disabled: true,

        },
        {
            field: "received",
            type: "number",
            headerName: "Recibe ",
            showFormNew: true,
            disabled: false,
            onChange: (value, formData, setFormData) => {
                setFormData({ ...formData, change_given: value - formData.total, received: value });

            }

        },
        {
            field: "change_given",
            type: "currency",
            headerName: "Devuelve",
            showFormNew: true,
            disabled: true,

        },

    ]);

    const handleOption = useCallback(() => {
        let listTrue;
        let listTrueBalence;

        if (selectedOption === "contado") {
            listTrue = ["name"];
            listTrueBalence = ["subtotal", "discount", "total", "received", "change_given"]
        } else {
            listTrue = ["name", "reg_number", "identification", "mobile", "send_phone"];
            listTrueBalence = ["balance_before", "subtotal", "balance_after"]
        }

        setColumnsForm(prevColumnsForm => {
            return prevColumnsForm.map(column => {
                return {
                    ...column,
                    showFormNew: listTrue.includes(column.field)
                };
            });
        });

        setColumnsFormBalence(prevColumnsForm => {
            return prevColumnsForm.map(column => {
                return {
                    ...column,
                    showFormNew: listTrueBalence.includes(column.field)
                };
            });
        });
    }, [selectedOption]);

    useEffect(() => {
        handleOption();
    }, [selectedOption, handleOption]);

    const putOrderNumber = useCallback(async (selectedOption) => {
        setIsLoading(true);
        try {
            const dataNumber = await TransactioncountsService.getAll();
            if (selectedOption === "contado") {
                setOrderNumber("CO-" + (dataNumber[0]["sales_consecutive"] + 1).toString().padStart(5, '0'));
                setDatacountnumber({ "id": dataNumber[0]["id"], "field": "sales_consecutive", "number": dataNumber[0]["sales_consecutive"] + 1 })
            } else if (selectedOption === "credito") {
                setOrderNumber("CR-" + (dataNumber[0]["sales_credit_consecutive"] + 1).toString().padStart(5, '0'));
                setDatacountnumber({ "id": dataNumber[0]["id"], "field": "sales_credit_consecutive", "number": dataNumber[0]["sales_consecutive"] + 1 })

            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (isMobile) {
            dispatch(setOpenMenu(false));
        }
        const datasalesStore = JSON.parse(localStorage.getItem("datasales"))
        if (datasalesStore) {
            setDataSales(datasalesStore)
        }
        putOrderNumber(selectedOption)

        localStorage.setItem('lastVisitedPage', location.pathname);
    }, [isMobile, selectedOption, putOrderNumber, dispatch, location]);

    useEffect(() => {
        if (datasales.items.length > 0) {
            localStorage.setItem("datasales", JSON.stringify(datasales));
        }
        else {
            localStorage.removeItem('datasales')
        }
    }, [datasales]);

    const calculateTotal = useCallback((updatedItems) => {
        let subtotal = 0;
        updatedItems.forEach(item => {
            subtotal += item.subtotal;
        });

        setDataSales(prevData => ({
            ...prevData,
            subtotal,
            total: subtotal - datasales.discount + datasales.balance_before,
            balance_after: subtotal + datasales.balance_before
        }));
    }, [datasales.balance_before, datasales.discount]);

    useEffect(() => {
        calculateTotal(datasales.items);
    }, [datasales.items, calculateTotal]);

    const fetchItems = useCallback(async (data) => {
        if (data["code"] === "") {
            setSnackbarMessage('Debe agregar un valor'); // Asegúrate de que setSnackbarMessage reciba un valor adecuado
            setOpenSnackbar(true);
            return
        }
        setLoadingButton(true);
        try {
            const dataItem = await itemService.getAll({ "code": data["code"] });


            if (dataItem[0].stock <= 0) {
                setSnackbarMessage(`No hay suficiente stock para el producto ${dataItem[0].code}`); // Asegúrate de que setSnackbarMessage reciba un valor adecuado
                setOpenSnackbar(true);
                return `No hay suficiente stock para el producto ${dataItem[0].code}`
            }

            if (Array.isArray(dataItem)) {
                // Creamos un nuevo array basado en los items actuales
                const updatedItems = [...datasales.items];

                dataItem.forEach(item => {
                    // Busca si el item ya existe en el array basado en el código
                    const existingItemIndex = updatedItems.findIndex(i => i.code === item.code);

                    if (existingItemIndex !== -1) {
                        // Si el item existe, suma la cantidad
                        updatedItems[existingItemIndex].quantity += 1
                        updatedItems[existingItemIndex].subtotal = (updatedItems[existingItemIndex].quantity * updatedItems[existingItemIndex].price)

                    } else {
                        // Si el item no existe, lo agrega al array
                        const newItem = {
                            code: item.code,
                            quantity: 1, // Cantidad por defecto
                            discount: 0,
                            subtotal: item.selling_price,
                            price: item.selling_price,
                            ...item // O cualquier otro campo que quieras incluir
                        };


                        updatedItems.push(newItem);
                    }
                });

                // Actualiza el estado `datasales` con el nuevo array de items
                setDataSales(prevData => ({
                    ...prevData,
                    items: updatedItems // Reemplazamos el array de `items` con el nuevo array actualizado
                }));

                // Después de procesar los items, fuerza el foco y la selección del input "code"
                if (inputRefCode.current) {
                    inputRefCode.current.focus();  // Forzar el enfoque en el input
                    inputRefCode.current.select(); // Seleccionar el contenido del input
                }
            }
            else {

                setSnackbarMessage('No se encontro ningun dato'); // Asegúrate de que setSnackbarMessage reciba un valor adecuado
                setOpenSnackbar(true);

            }
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        } finally {

            setLoadingButton(false);
        }
    }, [datasales.items]);

    useEffect(() => {
        if (scannedBarcode !== '' && !isFetchingItems) {  // Evita disparar múltiples solicitudes
            const updateCode = async () => {
                setIsFetchingItems(true);  // Marcamos que estamos solicitando los ítems
                try {
                    await fetchItems({ code: scannedBarcode });
                } catch (error) {
                    console.error(error);
                } finally {
                    setIsFetchingItems(false);  // Marcamos que hemos terminado
                    setScannedBarcode('');  // Limpiamos el valor una vez procesado
                }
            };

            updateCode();
        }
    }, [scannedBarcode, fetchItems, isFetchingItems]);


    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleEnterKey = async (e, type) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (e.target.value === "") {
                setSnackbarMessage('Debe agregar un valor'); // Asegúrate de que setSnackbarMessage reciba un valor adecuado
                setOpenSnackbar(true);
                return
            }
            if (type === "customer") {
                const data = {
                    "reg_number": e.target.value,
                    "identification": e.target.value,
                }
                await fetchCustomers(data);
            }
            else {
                const data = {
                    "code": e.target.value,
                }
                await fetchItems(data);
            }

        }
    };

    const handleEditCell = (rowIndex, columnId, value) => {
        setDataSales(prevData => {
            const updatedItems = [...prevData.items];
            // Lógica condicional según columnId
            if (columnId === 'quantity') {
                // Si columnId es 'quantity', realiza una acción específica
                const newSubtotal = (Number(value) * updatedItems[rowIndex].price); // Ejemplo: recalcular subtotal basado en cantidad
                updatedItems[rowIndex] = {
                    ...updatedItems[rowIndex],
                    quantity: Number(value),
                    subtotal: newSubtotal
                };
            }
            const newDataSales = { ...prevData, items: updatedItems };
            return newDataSales;
        });
    };


    const handleChane = (e, field_name) => {
        setDataSales(prevState => {
            let updatedData = { ...prevState, [field_name]: e };
            return updatedData;
        });
    };


    const handleDeleteRow = (row) => {
        const updatedItems = [...datasales.items];
        updatedItems.splice(row.id, 1);
        // Actualiza el estado `datasales` con el nuevo array de items
        setDataSales(prevData => ({
            ...prevData,
            items: updatedItems // Reemplazamos el array de `items` con el nuevo array actualizado
        }));
    };


    const fetchCustomers = async (data) => {

        if (data["reg_number"] === "" && data["identification"] === "") {
            setSnackbarMessage('Debe agregar un valor'); // Asegúrate de que setSnackbarMessage reciba un valor adecuado
            setOpenSnackbar(true);
            return
        }
        setLoadingButton(true);
        try {
            const dataCustomer = await CustomerService.getAll({
                "reg_number_or": data["reg_number"],
                "identification_or": data["identification"]
            });

            if (Array.isArray(dataCustomer) && dataCustomer.length > 0) {
                setDataSales(prevData => {
                    const updatedData = { ...prevData };

                    dataCustomer.forEach(customer => {
                        Object.keys(customer).forEach(key => {
                            if (key !== "items") {

                                if (key === "balance") {
                                    updatedData["balance_before"] = customer[key];
                                }
                                else {
                                    updatedData[key] = customer[key];
                                }

                            }
                        });

                        if (Array.isArray(customer.items) && customer.items.length > 0) {
                            updatedData.items = [
                                ...updatedData.items,
                                ...customer.items
                            ];
                        }
                    });
                    updatedData.balance_after = updatedData.balance_before + updatedData.total
                    return updatedData;
                });
            }
            else {

                setSnackbarMessage('No se encontro ningun dato'); // Asegúrate de que setSnackbarMessage reciba un valor adecuado
                setOpenSnackbar(true);

            }
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingButton(false);
        }
    };

    const submit = async (data) => {

        const newTrasaction1 = {
            "number": ordernumber.toLowerCase(),
            "type": "sales",
            "sub_type": selectedOption,
            "reg_number": datasales["reg_number"],
            "items": datasales["items"],
            "total": data["total"],
            "user": userData.username.toLowerCase(),
            "received": data['received'],
            "change_given": data['change_given'],
            "discount": data['discount'],
            "subtotal": data['subtotal'],
            "customer_name": datasales["name"].toLowerCase(),
            "status": "cerrada",
            "balance_before": data["balance_before"],
            "balance_after": data["balance_after"],
        }
        generateInvoicePdf(newTrasaction1)

        return "que rico un culo"
        // Se valida que hayan productos agregados en la venta.
        if (datasales["items"].length <= 0) {
            return "No hay datos para registrar"
        }
        // Si es una venta de credito se debe validar primero el cliente 
        let dataCustomer;
        if (selectedOption === "credito") {
            dataCustomer = await CustomerService.getAll({ "reg_number": datasales["reg_number"] });
            if (dataCustomer || datasales["balance_after"] > dataCustomer[0]["limit_credit"]) {
                return "El cliente no tiene cupo de credito"
            }
        }

        // Se valida que todos lo productos tengan el inventario suficiente para la venta
        let updatedItems = [];
        let logs = [];
        const productIds = datasales["items"].map(item => item.id);
        // Unir todos los IDs en un string separado por comas
        const productIdsString = productIds.join(',');
        const dataItems = await itemService.getAll({ "id_all": productIdsString });

        for (const item of datasales["items"]) {
            const product = dataItems.find(p => p.id === item.id);
            if (!product || product.stock < item.quantity) {
                return `No hay suficiente stock para el producto ${product.code}`
            }
            updatedItems.push({
                "updateOne": {
                    "filter": { "_id": item.id },
                    "update": { "$inc": { "stock": -item.quantity } }

                }
            });

            logs.push({
                "user": userData.username,
                "process": "update",
                "module": "items",
                "code": item.code,
                "name": item.name,
                "description": `Se vendieron ${item["quantity"]} unidades a un precio de venta de $ ${item["price"]} en la transaccion ${ordernumber}.`
            })


        }


        // //  se actualiza los productos con el nuevo inventario
        itemService.put("bulk", { "data_bulk_write": updatedItems }, true);


        // Si es una venta de credito se debe actualiza el cliente 
        if (selectedOption === "credito") {
            const newDataCustomer = {
                "name": datasales["name"],
                "send_phone": datasales["send_phone"],
                "mobile": datasales["mobile"],
                "balance": data["balance_after"]
            }
            const response = await CustomerService.put(dataCustomer["id"], newDataCustomer);
            if (response.status !== 200) {
                return `Se genero un error actualizando el cliente. Verifique ${datasales.toString()}`
            }

            await MessageService.post({
                "total": data["subtotal"],
                "reg_number": datasales["reg_number"],
                "balance": data["balance_after"],
                "order_number": ordernumber,
                "type": "sales",
                "phone": "57" + datasales["phone"],

            })

            logs.push({
                "user": userData.username,
                "process": "update",
                "module": "customers",
                "code": dataCustomer.reg_number,
                "name": dataCustomer.name,
                "description": `Se registro venta ${ordernumber} con un total de ${data["total"]}. el nuevo saldo es de ${data["balance_after"]}`
            })
        }

        const newTrasaction = {
            "number": ordernumber.toLowerCase(),
            "type": "sales",
            "sub_type": selectedOption,
            "reg_number": datasales["reg_number"],
            "items": datasales["items"],
            "total": data["total"],
            "user": userData.username.toLowerCase(),
            "received": data['received'],
            "change_given": data['change_given'],
            "discount": data['discount'],
            "subtotal": data['subtotal'],
            "customer_name": datasales["name"].toLowerCase(),
            "status": "cerrada",
            "balance_before": data["balance_before"],
            "balance_after": data["balance_after"],
        }
        generateInvoicePdf(newTrasaction)
        // console.log(newTrasaction)
        const response = await TransactionsService.post(newTrasaction)

        logs.push({
            "user": userData.username,
            "process": "create",
            "module": "transaction",
            "code": ordernumber,
            "name": datasales.name,
            "description": `Se registro venta ${ordernumber} con un total de ${data["total"]}`
        })

        let data_count = {};
        data_count[datacountnumber["field"]] = datacountnumber["number"];

        await TransactioncountsService.put(datacountnumber["id"], data_count)
        LogService.postMany(logs)
        return response
    }
    // Función que maneja el código escaneado
    const handleBarcodeScanned = (barcode) => {
        if (!loadingButton) {  // Evitamos hacer más requests si ya estamos cargando
            setScannedBarcode(barcode); // Esto activa el useEffect
        }
    };

    return (
        <div className="sale" >
            <div className="info">
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <div className="new-sale">
                        <div className="information-sale">
                            <div className="radio-group">
                                <input
                                    type="radio"
                                    id="contado"
                                    value="contado"
                                    checked={selectedOption === "contado"}
                                    onChange={() => {
                                        setSelectedOption("contado");

                                    }}
                                />
                                <label htmlFor="contado">Contado</label>

                                <input
                                    type="radio"
                                    id="credito"
                                    value="credito"
                                    checked={selectedOption === "credito"}
                                    onChange={() => {
                                        setSelectedOption("credito");

                                    }}
                                />
                                <label htmlFor="credito">Credito</label>
                            </div>
                            <div className="order-number">
                                <input
                                    type="text"
                                    value={ordernumber}
                                    className="form-control"
                                    disabled={true}
                                />
                            </div>
                        </div>


                        <div className="forms-new-sale">
                            <div className="right">


                                <Form
                                    data={datasales}
                                    slug=""
                                    columns={columnsForm}
                                    submit={submit}
                                    page="sales"
                                    action="add"
                                    showLastActivites={false}
                                    loadingButton={loadingButton}
                                    showButtomSubmmit={false}

                                />
                                {isScannerVisible && (
                                    <>
                                        {/* Siempre mantenemos montado el componente BarcodeScanner */}
                                        <BarcodeScanner
                                            setBarcode={(barcode) => {
                                                if (!loadingButton) {  // Solo procesamos el código si no estamos cargando
                                                    handleBarcodeScanned(barcode);
                                                }
                                            }}
                                        />

                                        {/* Controlamos la visibilidad del CircularProgress con estilos */}
                                        {loadingButton && (
                                            <div>
                                                <CircularProgress className="CircularProgress" />
                                            </div>
                                        )}
                                    </>
                                )}

                                <Form
                                    slug=""
                                    columns={columnsFormItems}
                                    submit={submit}
                                    page="sales"
                                    action="add"
                                    showLastActivites={false}
                                    loadingButton={loadingButton}
                                    showButtomSubmmit={false}
                                    inputRef={inputRefCode}
                                    inputRefName="code"

                                />
                            </div>
                            <div className="left">
                                <Form
                                    data={datasales}
                                    slug=""
                                    columns={columnsFormBalance}
                                    submit={submit}
                                    page="sales"
                                    action="add"
                                    showLastActivites={false}
                                    loadingButton={loadingButton}
                                    showButtomSubmmit={true}
                                    inputRef={inputRefCode}
                                    inputRefName="code"

                                />
                            </div>

                        </div>

                        <DataTable slug="customers" columns={columnsTable} rows={datasales.items} />

                        <Snackbar
                            open={openSnackbar}
                            autoHideDuration={6000}
                            onClose={handleCloseSnackbar}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        >
                            <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%', marginTop: '100px' }}>
                                {snackbarMessage}
                            </Alert>
                        </Snackbar>

                    </div>
                )}
            </div >
        </div >
    )
}

export default NewSale